<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title mb-0">ADD FILTER</p>
    </div>
    <div class="dialog-content">
      <b-container class="mt-3" fluid>
        <b-form @submit="onSubmit">
          <b-form-group label="Filter Name"
                        label-for="filterName"
                        :label-cols="3">
            <b-form-input id="filterName"
                          type="text"
                          aria-describedby="filterName-input"
                          placeholder="Enter Filter Name"
                          autocomplete="off"
                          @input="$v.form.filterName.$touch()"
                          v-model.trim="$v.form.filterName.$model"
                          :state="!$v.form.filterName.$error">
            </b-form-input>
            <b-form-invalid-feedback id="filterName-input">
              <span v-if="!$v.form.filterName.required">
                Filter Name is required.
              </span>
              <span v-if="!$v.form.filterName.uniqueFilterName &&
                      $v.form.filterName.required">
                Filter Name must be unique.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Filter File"
                        label-for="filterFile"
                        :label-cols="3">
            <label for="filterFile"></label>
              <b-form-file id="filterFile"
                     aria-describedby="filter-file"
                     placeholder="Choose a file..."
                     ref="filterFile"
                     v-model="form.filterFile"
                     @input="$v.form.filterFile.$touch()"
                     :state="!$v.form.filterFile.$error">
              </b-form-file>
              <b-form-invalid-feedback id="filterFile-input">
              <span v-if="!$v.form.filterName.required">
                Filter File is required.
              </span>
              </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Filter Icon"
                        label-for="filterIcon"
                        :label-cols="3"
                        v-if='!form.class'>
            <label for="filterIcon"></label>
              <b-form-file v-if='!form.class'
                      id="filterIcon"
                     aria-describedby="filter-icon"
                     placeholder="Choose a file..."
                     ref="filterFile"
                     v-model="form.filterIcon"
                     @input="$v.form.filterIcon.$touch()"
                     :state="!$v.form.filterIcon.$error">
              </b-form-file>
              <b-form-invalid-feedback id="filterIcon-input">
              <span v-if="!$v.form.filterIcon.required">
                Filter Icon is required.
              </span>
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Premium"
                        label-for="premiumType"
                        :label-cols="3">
            <toggle-button class="large-font"
                            id="premiumType"
                            v-model="form.premiumType"
                            :width="100"
                            :height="35"
                            :labels="{checked: 'YES', unchecked: 'NO'}"/>
          </b-form-group>
          <b-form-group label="Class"
                        label-for="class"
                        :label-cols="3">
            <toggle-button class="large-font"
                            id="class"
                            v-model="form.class"
                            :width="100"
                            :height="35"
                            :labels="{checked: 'Filter/FX',
                            unchecked: 'MASK'}"/>
          </b-form-group>
          <b-form-group label="Trigger"
                        label-for="class"
                        :label-cols="3">
          <select class="form-control"
                  v-model="form.trigger">
            <option v-for="(value, key) in triggerOptions"
                    :key="key"
                    :value="value.id">
              {{ value.label }}
            </option>
          </select>
          </b-form-group>
          <b-form-group label="Publish"
                        label-for="class"
                        :label-cols="3">
            <toggle-button class="large-font"
                            id="class"
                            v-model="form.publish"
                            :width="100"
                            :height="35"
                            :labels="{checked: 'YES',
                            unchecked: 'NO'}"/>
          </b-form-group>

          <!-- Buttons -->
          <div class="d-flex justify-content-end">
            <b-button class="text-center mt-3 ml-1"
                      type="submit"
                      variant="outline-success"
                      :disabled="$v.form.$invalid">
              Submit
            </b-button>
            <b-button class="text-center mt-3 ml-1"
                      variant="outline-danger"
                      @click="$emit('close')">
              Cancel
            </b-button>
          </div>
        </b-form>

        <!-- Loading -->
        <loading :active.sync="isLoading"
                 :is-full-page="true"
                 background-color="#000"
                 color="#068bcd"
                 :opacity="0.3"
                 :width="70"
                 :height="70">
        </loading>
      </b-container>
    </div>
  </div>
</template>
<script>
  const Loading = () => import('vue-loading-overlay');

  import _ from 'lodash';
  import { required, requiredIf } from 'vuelidate/lib/validators';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name : 'AddFilter',
    data() {
      return {
        form : {
          filterName  : null,
          premiumType : false,
          filterFile  : null,
          filterIcon  : null,
          class       : false,
          trigger     : 1,
          publish     : true,
        },
        isLoading        : false,
        progress         : 0,
        placeholder      : 'Choose an image',
        uniqueFilterName : true,
        triggerOptions   : [
          { id    : 1,
            label : 'None'},
          { id    : 2,
            label : 'Open Mouth'},
          { id    : 3,
            label : 'Tap Screen'} ],
      };
    },
    computed : {
      filterName() {
        return this.form.filterName;
      },
    },
    watch : {
      filterName(value) {
        if (value)
          this.checkFilterName(value);
      },
    },
    components : {
      Loading,
    },
    methods : {

      /**
       * Submit Form - Add Filter
       * @param e
       */
      onSubmit(e) {
        e.preventDefault();
        this.isLoading = true;
        this.uploadFile();
      },

      /**
       * Upload Filter Thumbnail
       * @param categID
       */
      async uploadFile() {
        const formData = new FormData();
        formData.append('filterIcon', this.form.filterIcon);
        formData.append('filterFile', this.form.filterFile);
        formData.append('name', this.form.filterName);
        formData.append('subscriptionType', this.form.premiumType ? 1 : 0);
        formData.append('triggerType', this.form.trigger);
        formData.append('class', this.form.class ? 1 : 0);
        formData.append('published', this.form.publish ? 'yes' : 'no');

        try {
          const res = await this.$http.post('/api/filter', formData, {
            onUploadProgress : e =>
              this.progress =  Math.round(e.loaded * 100 / e.total),
          });

          if (res) {
            this.closeModal();
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success',
              text  : 'Added Filter Successfully!',
            });
          }
        } catch (error) {
          this.isLoading = false;
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }

      },

      /**
       * Check if Filter Name is unique
       */
      checkFilterName : _.debounce(function(name) {
        this.$http.get('api/checkFilterName', {
          params : {
            filterName : name,
          },
        }).then(response => {
          this.uniqueFilterName = (response.data) ? false : true;
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      }, 500),

      /**
       * Check if File type is valid
       */
      onFileTypeMismatch () {
        this.placeholder = 'Please select a jpeg or png file.';
      },

      /**
       * Close Modal
       */
      closeModal() {
        this.isLoading = false;
        this.$parent.$emit('reload-table');
        this.$emit('close');
      },
    },
    validations : {
      form : {
        filterName : {
          required,
          uniqueFilterName() {
            return this.uniqueFilterName;
          },
        },
        filterFile : {
          required,
        },
        filterIcon : {
          required : requiredIf(function(form){
            return !form.class
          }),
        },
      },
      
    },
  };
</script>
<style lang="scss" scoped>
</style>